import React, { useState, useEffect, memo } from "react";
import "./App.css"; // Import a separate CSS file for styling
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import MovingComponent from "react-moving-text";
const ParticleBackground = memo(() => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        fullScreen: true,
        background: {
          image: "linear-gradient(45deg, #87CEEB 0%, #FFC0CB 100%)",
        },
        particles: {
          number: { value: 25, density: { enable: true, value_area: 600 } },
          color: { value: "#ffffff" },
          shape: {
            type: "circle",
            stroke: { width: 0, color: "#000000" },
          },
          opacity: {
            value: 0.25,
            random: true,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
          },
          size: {
            value: 20,
            random: true,
            anim: { enable: false, speed: 2, size_min: 0.1, sync: false },
          },
          line_linked: {
            enable: false,
            distance: 300,
            color: "#ffffff",
            opacity: 0,
            width: 0,
          },
          move: {
            enable: true,
            speed: 1,
            direction: "top-right",
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: true, mode: "repulse" },
            onclick: { enable: false, mode: "repulse" },
            resize: false,
          },
          modes: {
            grab: { distance: 800, line_linked: { opacity: 1 } },
            bubble: {
              distance: 790,
              size: 79,
              duration: 2,
              opacity: 0.8,
              speed: 3,
            },
            repulse: { distance: 100, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 },
          },
        },
        retina_detect: false,
      }}
    />
  );
});
const App = () => {
  const sentences = [
    "We are almost at the end of the celebratory week.",
    "I wrote front end, you worked hard to solve the riddles.",
    "And here we are on the date day and most importantly the day you see the gifts.",
    "I chose a place I hope you love and enjoy.",
    "I hope I made it a birthday you'll never forget like I promised.",
    "I hope the narrative of you hating your birthday ends this year.",
    "Let's just say I'm not done yet.",
    "Hopefully you're filled with excitement and joy.",
    "Hopefully I made you feel special.",
    "Hopefully I made you the happiest you've ever been.",
    "I only ever promised you one thing, and that is that I'll always do my best to see you happy.",
    "Yeah one more thing",
    "IMAGE"
  ];

  const [visibleSentence, setVisiblity] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]);

  const [mainSentence, setMainSentence] = useState(false);
  const [renderChildren, setRenderChildren] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setMainSentence(true);
      setTimeout(() => {
        setRenderChildren(true);
      }, 1000);
    }, 1000);
  }, []);

  function renderMain() {
    if (mainSentence) {
      return <h2>Nourhan</h2>;
    }
  }
  function renderSentence(index) {
    if (visibleSentence[index] && index === 12) {
      return <img src={`${process.env.PUBLIC_URL}/potato.jpeg`}  className="amy" alt=""/>
    }
    if (visibleSentence[index]) {
      return <p> {sentences[index]} </p>
    } else {
      return "";
    }
  }

  function renderrr() {
    if (renderChildren) {
      return (
        <div className="body">
          {sentences.map((_, idx) => (
            <MovingComponent
              key={idx}
              type="fadeIn"
              duration="2000ms"
              delay={`${idx * 2}s`}
              direction="normal"
              iteration="1"
              timing="ease-in"
              fillMode="none"
              onAnimationEnd={() => {
                let temp = [...visibleSentence];
                temp[idx + 1] = true;
                setVisiblity(temp);
              }}
            >
              {renderSentence(idx)}
            </MovingComponent>
          ))}
        </div>
      );
    }
  }

  return (
    <div className="app">
      <ParticleBackground />
      <div className="content">
        <MovingComponent
          type="fadeIn"
          duration="5000ms"
          delay="0s"
          direction="normal"
          iteration="1"
          timing="ease-in"
          fillMode="none"
        >
          <div>
            <h2>{renderMain()}</h2>
            {renderrr()}
          </div>
        </MovingComponent>
      </div>
    </div>
  );
};

export default App;
